import CardContent from "@mui/joy/CardContent";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import {PieChart} from "@mui/x-charts/PieChart";
import Card from "@mui/joy/Card";

const PieChartCard = (data) => {
    const name = data.name
    const time = data.time
    const chartData = data.chartData
    const colorPalette = ['#e2af61', '#d298c4', '#6f828e', '#f2c003', '#e35b27', '#ab2d2c'];

    function channel_name_cleaner(name) {
        name = name.replaceAll('Album of the Week', 'AOTW');
        name = name.replaceAll('Season 2', 'S2');
        return name;
    }

    return (
        <Card sx={{ backgroundColor: '#111111', overflow: 'auto' }}>
            <CardContent>
                <Stack direction="column" spacing={-1}>
                    <Typography level='title-md' sx={{ textAlign: 'left', color: '#FFFFFF'}}>
                        {name}
                    </Typography>
                    <Typography level='body-sm' sx={{ textAlign: 'left'}}>
                        Since {time} ago
                    </Typography>
                </Stack>
            </CardContent>
            <CardContent>
                <PieChart
                    colors={colorPalette}
                    series={[
                        {
                            data: [
                                { id: chartData[0]['id'], value: chartData[0]['message_count'], label: channel_name_cleaner(chartData[0]['label']) },
                                { id: chartData[1]['id'], value: chartData[1]['message_count'], label: channel_name_cleaner(chartData[1]['label']) },
                                { id: chartData[2]['id'], value: chartData[2]['message_count'], label: channel_name_cleaner(chartData[2]['label']) },
                                { id: chartData[3]['id'], value: chartData[3]['message_count'], label: channel_name_cleaner(chartData[3]['label']) },
                                { id: chartData[4]['id'], value: chartData[4]['message_count'], label: channel_name_cleaner(chartData[4]['label']) }
                            ],
                            highlightScope: { fade: 'global', highlight: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                            innerRadius: 40,
                            cornerRadius: 4,
                            paddingAngle: 2,
                            cx: 100
                        },
                    ]}
                    width={400}
                    height={200}
                    slotProps={{
                        legend: {
                            padding: -8,
                            labelStyle: {
                                fill: '#FFFFFF'
                            }
                        }
                    }}
                />
            </CardContent>
        </Card>
    );
}

export default PieChartCard;