import CardContent from "@mui/joy/CardContent";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";

const BillboardCard = (data) => {
    const name = data.name;
    const value = data.value;
    const time = data.time;

    if (time === null) {
        return (
            <Card sx={{ backgroundColor: "#111111", width: '100%' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Stack direction="column" spacing={-1}>
                        <Typography level='h1' sx={{ textAlign: 'left', color: '#FFFFFF'}}>
                            {value}
                        </Typography>
                        <Stack direction="column" spacing={-1}>
                            <Typography level='body-sm' sx={{ textAlign: 'left'}}>
                                {name}
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        );
    } else {
        return (
            <Card sx={{ backgroundColor: "#111111", width: '100%' }}>
                <CardContent sx={{ width: '100%' }}>
                    <Stack direction="column" spacing={-1}>
                        <Typography level='h1' sx={{ textAlign: 'left', color: '#FFFFFF'}}>
                            {value}
                        </Typography>
                        <Stack direction="column" spacing={-1}>
                            <Typography level='body-sm' sx={{ textAlign: 'left', color: '#FFFFFF'}}>
                                {name}
                            </Typography>
                            <Typography level='body-xs' sx={{ textAlign: 'left'}}>
                                Since {time} ago
                            </Typography>
                        </Stack>
                    </Stack>
                </CardContent>
            </Card>
        );
    }
}

export default BillboardCard;