import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Stack from "@mui/joy/Stack";
import Table from '@mui/joy/Table';
import Typography from "@mui/joy/Typography";

import './styles/table.css';

const AllTimeCard = (data) => {
    const user_data = data.users;

    return (
        <Card sx={{ backgroundColor: '#111111' }}>
            <CardContent sx={{ width: '100%'}}>
                <Box>
                    <Stack direction="column" spacing={-1} sx={{ width: '100%' }}>
                    <Typography level='title-lg' sx={{ textAlign: 'left', color: '#FFFFFF'}}>
                        Most Active Users
                    </Typography>
                    <Typography level='body-sm' sx={{ textAlign: 'left'}}>
                        All Time
                    </Typography>
                </Stack>
                </Box>
                <Box sx={{ overflow: 'auto' }}>
                    <Table stickyHeader size="sm" sx={{ width: '100%' }}>
                        <thead>
                            <tr className='table-header'>
                                <th>Name</th>
                                <th>Messages</th>
                            </tr>
                        </thead>
                        <tbody>
                            {user_data.map((user) => (
                                <tr key={user['fan_id']} className='table-row'>
                                    <td>{user['fan']}</td>
                                    <td>{user['message_count']}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Box>
            </CardContent>
        </Card>
    );
}

export default AllTimeCard;